import React, { useState } from 'react'
import './DonorManagement.css'
import { Grid, Typography, Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add'
import DonorManagementTabel from './DonorManagementTabel';

const DonorManagement = () => {


    return (
        <div className='Donor'>
            <Grid item xs={12} md={12} lg={12}>
                <Stack className='DonorText'>
                    <Typography gutterBottom variant="h5" component="div" className='DonorHeading'>
                        Donor Management
                    </Typography>
                </Stack>
                <DonorManagementTabel />
            </Grid>

        </div>
    )
}

export default DonorManagement
