import React, { createContext, useContext, useState } from "react";

// Create a context
const PaymentContext = createContext();

// Create a custom hook to access the context
export const usePayment = () => {
  return useContext(PaymentContext);
};

// Create a provider component
export const PaymentProvider = ({ children }) => {
  // Define your state and functions here
  const [paymentAmount, setPaymentAmount] = useState();
  const [donationAmount, setDonationAmount] = useState();
  const [selectedPg, setSelectedPg] = useState();

  const [cryptoStatus, setCryptoStatus] = useState();
  const [cryptoTxnID, setCryptoTxnID] = useState();
  const [donorName, setDonorName] = useState();
  const [donorEmail, setDonorEmail] = useState();
  const [qrUrl, setQrUrl] = useState("");
  const [address, setAddress] = useState("");
  const [cryptoAmt, setCryptoAmt] = useState("");
  const [timeout, settimeout] = useState(7200);

  return (
    <PaymentContext.Provider
      value={{
        timeout,
        settimeout,
        cryptoAmt,
        setCryptoAmt,
        qrUrl,
        setQrUrl,
        address,
        setAddress,
        paymentAmount,
        setPaymentAmount,
        donationAmount,
        setDonationAmount,
        selectedPg,
        setSelectedPg,
        cryptoStatus,
        setCryptoStatus,
        cryptoTxnID,
        setCryptoTxnID,
        donorName,
        setDonorName,
        donorEmail,
        setDonorEmail,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
