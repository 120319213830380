import React, { useEffect, useState } from 'react';
import { Stack, Typography, Box, IconButton } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { getAllDonor } from '../../Lib/DonorApi';
import { extractDate, formatNumber } from '../../Lib/ApiCaller';
import { makeStyles } from '@mui/styles';
import * as XLSX from 'xlsx';

const columns = [
    { id: 'sno', label: 'Sr no.', minWidth: 50 },
    { id: 'name', label: 'Donor Name', minWidth: 80 },
    { id: 'projectName', label: 'Project Name', minWidth: 150, align: 'left' },
    { id: 'amount', label: 'Donation Amount', minWidth: 100, align: 'left' },
    { id: 'paymentMethod', label: 'Payment Method', minWidth: 10, align: 'left' },
    { id: 'transectionId', label: 'Invoice ID', minWidth: 150, align: 'left' },
    { id: 'createdAt', label: 'Date', minWidth: 115, align: 'left' },
];


const useStyles = makeStyles({
    hiddenPagination: {
        '& .MuiTablePagination-selectLabel': {
            display: 'none !important',
        },
        '& .MuiInputBase-root.MuiTablePagination-select': {
            display: 'none !important',
        },
        '& .MuiInputBase-root MuiInputBase-colorPrimary MuiTablePagination-input css-16c50h-MuiInputBase-root-MuiTablePagination-select': {
            display: 'none',
        },
    },
    inputPadding: {
        '& .MuiInputBase-root .MuiInputBase-input': {
            padding: '11px 8px 8px 0px !important',
        },
    },
});


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const DonorManagementTabel = ({ handleOpenView, handleOpenEdit }) => {

    const classes = useStyles();


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filter, setFilter] = useState('projectName');
    const [filterDate, setFilterDate] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [donorData, setDonorData] = useState([]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeFilter = (event) => {
        setFilter(event.target.value);
    };

    const handleChangeFilterDate = (event) => {
        setFilterDate(event.target.value);
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const fetchAllDonor = async () => {
        let response = await getAllDonor();
        setDonorData(response?.data?.reverse());
    };

    useEffect(() => {
        fetchAllDonor();
    }, []);

    const filteredData = donorData.filter((row) => {
        const matchesSearch = Object.values(row).some(
            (value) =>
                value &&
                value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );

        // Filter based on Project Name or Donor Name
        const matchesFilter = (() => {
            if (!filter) return true;
            if (filter === 'projectName') return row.projectName?.toLowerCase().startsWith(searchQuery.toLowerCase());
            if (filter === 'donorName') return row.name?.toLowerCase().startsWith(searchQuery.toLowerCase());
            if (filter === 'paymentMethod') return row.paymentMethod?.toLowerCase().startsWith(searchQuery.toLowerCase());
            return true;
        })();

        const matchesDate = (() => {
            if (!filterDate) return true;
            const today = new Date();
            const rowDate = new Date(row.createdAt);
            if (filterDate === 'Today') {
                return rowDate.toDateString() === today.toDateString();
            } else if (filterDate === '7 Days') {
                const weekAgo = new Date(today);
                weekAgo.setDate(today.getDate() - 7);
                return rowDate >= weekAgo && rowDate <= today;
            }
            return true;
        })();

        return matchesSearch && matchesFilter && matchesDate;
    });

    const exportToExcel = () => {
        const exportData = filteredData.map((row, index) => {
            return {
                'Sr no.': index + 1, // Generate serial number starting from 1
                'Id': row?._id,
                'Donor Name': row.name,
                'Project Name': row.projectName,
                'Donation Amount': `$ ${formatNumber(row.amount)}`,  // Apply formatting directly
                'Payment Method': row.paymentMethod,
                'Invoice ID': row.transectionId,
                'Date': extractDate(row.createdAt),  // Apply date formatting directly
            };
        });

        // Create a new worksheet from the prepared data
        const ws = XLSX.utils.json_to_sheet(exportData);

        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Donors');

        // Write the workbook to a file
        XLSX.writeFile(wb, 'Donors.xlsx');
    };

    return (
        <Paper sx={{ width: '100%' }} className="DonorTabel">
            <TableContainer sx={{ borderRadius: '20px' }}>
                <Table stickyHeader aria-label="sticky table">
                    {/* Table Head */}
                    <TableHead>
                        {/* Search Row */}
                        <TableRow>
                            <TableCell align="end" colSpan={3}>
                                {/* Search Bar */}
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography className='DashboardTabelCellText'>
                                        Donor List
                                    </Typography>
                                    <Search className='UpperNavbarSearch'>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search here…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            value={searchQuery}
                                            onChange={handleSearch}
                                            className={classes.inputPadding}

                                        />
                                    </Search>
                                    <FormControl>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filter}
                                            displayEmpty
                                            onChange={handleChangeFilter}
                                            className="DonorFilterDropDown"
                                            IconComponent={FilterAltOutlinedIcon}
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                },
                                                ' & .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                                                    padding: '10px 22px 10px 12px'
                                                }
                                            }}
                                        >
                                            <MenuItem value="projectName">Project Name</MenuItem>
                                            <MenuItem value="donorName">Donor Name</MenuItem>
                                            <MenuItem value="paymentMethod">Pay By</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </TableCell>
                            <TableCell align='right' colSpan={4}>
                                <Stack className='DashboardTabelCellRight'>
                                    <Typography className='DashboardTabelCellRightText'>
                                        Sort By
                                    </Typography>
                                    <Box>
                                        <FormControl>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={filterDate}
                                                displayEmpty
                                                onChange={handleChangeFilterDate}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    ' & .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                                                        padding: '10px 22px 10px 12px'
                                                    }
                                                }}
                                                className='DashboardFilterDropDown'
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                <MenuItem value="Today">Today</MenuItem>
                                                <MenuItem value='7 Days'>Last 7 Days</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Stack className='DashboardTabelCellRight'>
                                        <Typography onClick={exportToExcel} style={{ cursor: 'pointer', border: '2px solid #e6eaf8', borderRadius: '10px', padding: '6px', fontWeight: '700' }} className='DashboardTabelCellRightText'>
                                            Export to Excel
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </TableCell>
                        </TableRow>
                        {/* Table Header Row */}
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    className="DonorTabelHeader"
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {/* Table Body */}
                    <TableBody>
                        {filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                                {columns.map((column) => {
                                    let value = row[column.id];
                                    let filt = filteredData?.length
                                    if (column.id === 'createdAt') {
                                        value = extractDate(value);
                                    }
                                    if (column.id === 'sno') {
                                        // The actual serial number considering the current page
                                        value = (page * rowsPerPage + index + 1).toString().padStart(2, '0');
                                    }
                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            className="DonorTableCellRow"
                                        >
                                            {column.id === 'amount' ? `$ ${formatNumber(value)}` : value}
                                            {/* {value} */}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Table Pagination */}
            <TablePagination
                component="div"
                count={filteredData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                className={classes.hiddenPagination}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default DonorManagementTabel;


