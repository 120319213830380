import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Stack,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Button,
  Autocomplete,
  InputLabel,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./Payment.css";
import { usePayment } from "../../Context/PaymentContext";
import AsyncTextRenderer from "../../Context/AsyncTextRenderer";
import { cryptoPaymentRes, paymentRes } from "../../Lib/PaymentApi";
import WordOfSupport from "./WordOfSupport";
import { addDonor } from "../../Lib/DonorApi";
import { useWordOfSupport } from "../../Context/WordOfSupportContext";
import CloseIcon from "@mui/icons-material/Close";

const CryptoPaymentModal = ({
  cryptoOpen,
  handleCloseCrypto,
  handleClear,
  fetchDashboard,
  handleOpen,
  cryptoPaymentType,
}) => {
  const {
    address,
    cryptoAmt,
    cryptoStatus,
    cryptoTxnID,
    setCryptoStatus,
    paymentAmount,
    donorName,
    donorEmail,
    qrUrl,
    setQrUrl,
    timeout,
  } = usePayment();
  const { setDonatedAmt } = useWordOfSupport();

  const fetchStatus = async () => {
    let sessionDataString = localStorage.getItem("projectListData");
    let name = localStorage.getItem("donorName");
    let email = localStorage.getItem("donorEmail");
    let checkBox = localStorage.getItem("checkBox") || "false";
    const sessionData = JSON.parse(sessionDataString);
    let response = await cryptoPaymentRes(cryptoTxnID);
    setCryptoStatus(response?.message);

    if (response?.message === "completed") {
      let res = response?.paymentInfo;
      setDonatedAmt(paymentAmount);
      await paymentRes(
        sessionData?._id,
        "Crypto",
        response?.message,
        cryptoTxnID,
        res?.coin,
        res?.amountf,
        new Date().toISOString(),
        name,
        email,
        checkBox
      );
      await addDonor(
        "Crypto",
        paymentAmount,
        sessionData?._id,
        cryptoTxnID,
        name,
        email,
        checkBox,
        res?.coin,
        res?.amountf
      );
      handleOpen();
      handleClear();
      fetchDashboard();
      handleCloseCrypto();
      localStorage.removeItem("checkBox");
      localStorage.removeItem("donorName");
      localStorage.removeItem("donorEmail");
      return "completed";
    }

    return response?.message;
  };

  const [timeLeft, setTimeLeft] = useState(0);
  const [hasClose, setHasClose] = useState(true);
  const [timer, setTimer] = useState(120);
  const [HasTimeOutStarted, setHasTimeOutStarted] = useState(false);

  useEffect(() => {
    if (cryptoOpen) {
      setHasClose(true);
      setTimer(120);
    }
  }, [cryptoOpen]);

  useEffect(() => {
    if (timer <= 0) setHasClose(false);
  }, [timer]);

  useEffect(() => {
    const tt = setInterval(() => {
      setTimer((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(tt);
  }, []);

  useEffect(() => {
    if (timeout) {
      setTimeLeft(timeout);
      if (timeout > 0) {
        setHasTimeOutStarted(true);
        const timer = setInterval(() => {
          setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(timer); // Clean up the interval on component unmount
      }
    }
  }, [cryptoTxnID]);

  useEffect(() => {
    if (HasTimeOutStarted && timeLeft <= 0) {
      setHasTimeOutStarted(false);
      //   toast.error(t("toast41"));
      handleCloseCrypto();
    }
  }, [timeLeft]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const formatedTimeLeft = useMemo(() => formatTime(timeLeft), [timeLeft]);
  const formatedTimerLeft = useMemo(() => formatTime(timer), [timer]);

  useEffect(() => {
    let intervalId;
    if (cryptoTxnID !== undefined && cryptoOpen) {
      fetchStatus(); // Call immediately on mount
      intervalId = setInterval(fetchStatus, 5000); // Call every 5 seconds
    }

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [cryptoOpen]);

  //   return (
  //     <div>
  //       <Modal
  //         open={cryptoOpen}
  //         onClose={handleCloseCrypto}
  //         aria-labelledby="modal-modal-title"
  //         aria-describedby="modal-modal-description"
  //       >
  //         <Box className="CryptoModal">
  //           <Stack className="CryptoClose">
  //             <CloseIcon
  //               onClick={() => handleCloseCrypto()}
  //               className="CryptoCloseIcon"
  //             />
  //           </Stack>
  //           <Typography className="CryptoPaymentModalHeading">
  //             <AsyncTextRenderer text="Payment Status!" />
  //           </Typography>
  //           <Typography
  //             className="CryptoPaymentModalPara"
  //             style={{ display: "flex", justifyContent: "center" }}
  //           >
  //             <AsyncTextRenderer text={cryptoStatus} />
  //           </Typography>
  //           <Stack className="CryptoModalButton">
  //             {/* <Typography className='CryptoButtonClose' onClick={() => handleCloseCrypto()}><AsyncTextRenderer text="Close" /></Typography> */}
  //             <Typography
  //               className="CryptoButtonPaymentModal"
  //               onClick={() => fetchStatus()}
  //             >
  //               <AsyncTextRenderer text="Refresh" />
  //             </Typography>
  //           </Stack>
  //         </Box>
  //       </Modal>
  //       <WordOfSupport open={open} handleClose={handleClose} />
  //     </div>
  //   );

  return (
    <>
      <Modal
        open={cryptoOpen}
        onClose={() => {}}
        aria-labelledby="new-modal-title"
        aria-describedby="new-modal-description"
      >
        <Box
          className="membership-grid1"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: 550, // Adjust width for mobile and desktop devices
            maxWidth: "85%",
            maxHeight: "90dvh",
            overflow: "auto",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <Box>
            <Box className="SubHeader">
              <Typography
                variant="h5"
                id="new-modal-title"
                sx={{
                  borderBottom: 1,
                  py: 2,
                  pt: 0,
                  mb: 1,
                  borderColor: "#E6E6E6",
                }}
                gutterBottom
              >
                <AsyncTextRenderer text={"Coin Payments"} />
                <Button
                  color="success"
                  onClick={handleCloseCrypto}
                  disabled={hasClose}
                  sx={{ float: "right", gap: 1 }}
                >
                  <AsyncTextRenderer text={hasClose ? "CLose in" : "Close"} />{" "}
                  {hasClose && formatedTimerLeft}
                </Button>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={`${qrUrl}`} alt="fi" />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 1,
                }}
              >
                <Typography variant="p" sx={{ fontWeight: 500 }}>
                  <AsyncTextRenderer text={"Amount"} />
                </Typography>
                <Typography variant="p" sx={{ fontWeight: 500 }}>
                  {cryptoAmt} {cryptoPaymentType}
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="p"
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: 17,
                pt: 1,
              }}
            >
              <AsyncTextRenderer
                text={"Please send sufficient amount for transaction fee"}
              />
            </Typography>
            <Box sx={{ py: 3 }}>
              <InputLabel htmlFor="coin-input" sx={{ color: "#000" }}>
                <AsyncTextRenderer text={"Address"} />
              </InputLabel>
              <FormControl fullWidth={true} sx={{ mb: 2 }}>
                <OutlinedInput
                  id="coin-input"
                  value={address}
                  sx={{ fontFamily: "inherit!important", fontSize: 16 }}
                />
              </FormControl>
              <Typography
                variant="p"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 17,
                  py: 1,
                }}
              >
                <AsyncTextRenderer text={"Payment ID"} /> : {cryptoTxnID}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: "bold",
                    fontSize: 17,
                    py: 1,
                  }}
                >
                  <AsyncTextRenderer text={"Time Left"} />:{" "}
                  <span style={{ color: "#000", paddingLeft: "2px" }}>
                    {formatedTimeLeft}
                  </span>
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    display: "flex",
                    color: "#D20000",
                    justifyContent: "center",
                    fontSize: 17,
                    pt: 4,
                    flexDirection: "column",
                    gap: "7px",
                  }}
                >
                  <AsyncTextRenderer text={"Note:-"} />

                  <span
                    style={{
                      fontWeight: "bold",
                      textAlign: "center !important",
                    }}
                  >
                    <AsyncTextRenderer
                      text={
                        "PLEASE DO NOT GO BACK OR RELOAD THIS PAGE WHILE TRANSACTION IS IN PROCESS"
                      }
                    />
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CryptoPaymentModal;
