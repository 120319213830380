import { getApiCaller, postApiCaller } from "./ApiCaller";

export const addDonor = async (paymentMethod, amount, projectId, txnId,  donorName, donorEmail, checkBox, coin, coinVal, ) => {
    let headers = {
        'Content-Type': 'application/json',
    }

    let body = {
        paymentMethod: paymentMethod,
        amount: amount,
        projectId: projectId,
        transectionId: txnId,
        coin : coin,
        coinValue : coinVal,
        name : donorName,
        email : donorEmail,
        checkBox : checkBox
    }

    let response = await postApiCaller('donation/add', body, { headers })
    return response;
}

export const getAllDonor = async () => {

    let headers = {
        'Content-Type': 'application/json',
    }

    let response = await getApiCaller('donation/getAllDonation', { headers })
    return response;
}

export const getProjectDonation = async (projectId) => {

    let headers = {
        'Content-Type': 'application/json',
    }

    let response = await getApiCaller(`donation/getAll/${projectId}`, { headers })
    return response;
}